<template>
	<v-card>
		<v-card-title>Linked T2P Devices</v-card-title>
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<v-data-table ref="dataTable" :headers="headers" :items="devices" :loading="status.loading" class="elevation-1">
						<template v-slot:item.history="{ item }">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn text v-on="on" color="accent" @click="fetchHistory(item)">
										<v-icon>mdi-view-list</v-icon>
									</v-btn>
								</template>
								<span>Transaction History</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn text v-on="on" color="accent" @click="fetchLinkedHistory(item)">
										<v-icon>link</v-icon>
									</v-btn>
								</template>
								<span>Link History</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "OrganisationsDevice",

	computed: {
		devices: function() {
			return this.$store.state.devices.devices;
		},
		status: function() {
			return this.$store.state.devices.status;
		},
		selectedOrganisation: function() {
			return this.$store.state.organisations.selectedOrganisation;
		}
	},

	data: function() {
		return {
			headers: [
				{ text: "Terminal ID", value: "TerminalId", align: "left", sortable: false },
				{ text: "Shop Code", value: "ShopCode", align: "left", sortable: false },
				{ text: "Description", value: "Description", align: "left", sortable: false },
				{ text: "History", value: "history", align: "right", type: "action" }
			]
		};
	},

	mounted: function() {
		this.$store.dispatch("devices/fetchLinkedDevices", this.selectedOrganisation.id);
	},

	methods: {
		async fetchHistory(item) {
			let date = Date.now();
			let d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			let historyDate = [year, month, day].join("-");

			let params = { id: item.TerminalId, date: historyDate };

			let terminalId = await this.$store.dispatch("devices/fetchLinkedDeviceHistory", params);

			if (terminalId) {
				this.$router.push({ name: `organisation-device-history` });
			}
		},
		async fetchLinkedHistory(item) {
			let terminalId = await this.$store.dispatch("devices/fetchLinkedDeviceLinkHistory", item.TerminalId);

			if (terminalId) {
				this.$router.push({ name: `organisation-device-link-history` });
			}
		}
	}
});
</script>
